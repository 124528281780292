import React  from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ScheduleDemoModal(props) {

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      // backgroundColor: 'rgba(188, 203, 204, 1)',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
      // backgroundColor: 'rgba(188, 203, 204, 1)',
    },
    '& .MuiDialog-paper': {
      backgroundColor: 'rgba(188, 203, 204, 1)',
      borderRadius: '25px',
    }
  }));


        
  return (
    <BootstrapDialog
      maxWidth="lg"
      fullWidth="true"
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      // open={true}
    >
      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          padding: '5px',
          color: 'black',
        }}
      >
      <CloseIcon />
      </IconButton>
      <DialogContent>
        <iframe
          title='Demo Scheduling Calendar'
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3vSXCnEUCEMrbNqSeyHF5RQWDf2qbBYUvCyxcNOJXxXY7iDhByQ16QQk3LOz7VFmNsU_5tMk1o?gv=true"
          className='book_demo_iframe' ></iframe>
      </DialogContent>
    </BootstrapDialog>
  );
}