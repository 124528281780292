import React, { useState } from "react"
import ScheduleCallModal from "./ScheduleCallModal";

function ButtonScheduleCall(props, ref) {
    
    const { className, ...rest } = props;

    const buttonClassName = className ? `button_call ${className}` : `button_call`

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <button onClick={handleClickOpen} ref={ref} className={buttonClassName} {...rest}>Get in touch</button>
            <ScheduleCallModal
                handleClickOpen={ () => handleClickOpen() }
                handleClose={ () => handleClose() }
                isOpen={open}
            />
        </>
    )
}

export default React.forwardRef(ButtonScheduleCall);