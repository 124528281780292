import React from "react"
import ButtonScheduleCall from "./ButtonScheduleCall"
import ButtonScheduleDemo from "./ButtonScheduleDemo";


export default function MainSection3() {
   
    return (
        <div className="main_section3">
            <div className="main_section3_content">
                <section className="features">
                    <h3>Features</h3>
                        <div className="features_box">
                            <div className="features_content">
                                <ul className="list_features">
                                    <li>Algorithm for OECD-compliant transfer pricing/ intra-group cash pool transaction interest rates</li>
                                    <li>Physical, notional, in-house bank, global cash pools</li>
                                    <li>Target balance cash pooling</li>
                                </ul>
                                <ul className="list_features">
                                    <li>Structural position monitoring</li>
                                    <li>Functions and risks analysis</li>
                                    <li>Spread allocation calculator</li>
                                    <li>Export reports, graphs, and assessments</li>
                                </ul>
                            </div>
                        </div>
                </section>
                <section className="call_to_action">
                    <h3>Ready to get started?</h3>
                    <ButtonScheduleCall />
                    <ButtonScheduleDemo />
                </section>
            </div>
        </div>
    )
}
