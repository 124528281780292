import React, { useState } from "react"
import ScheduleDemoModal from "./ScheduleDemoModal";

function ButtonScheduleCall(props, ref) {
    
    const { className, ...rest } = props;

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <p onClick={handleClickOpen} ref={ref} className="book_demo" {...rest}>or book a demo</p>
            <ScheduleDemoModal
                handleClickOpen={ () => handleClickOpen() }
                handleClose={ () => handleClose() }
                isOpen={open}
            />
        </>
    )
}

export default React.forwardRef(ButtonScheduleCall);