import React, { useState, useEffect, useRef } from "react"
import ButtonScheduleCall from "./ButtonScheduleCall"
import section1_laptop_pic from "../images/laptop_pic.png"
import section1_laptop_pic_front from "../images/Laptop_Front_Cash_Pool.png"
import ButtonScheduleDemo from "./ButtonScheduleDemo";

export default function MainSection1() {
    
    const [isVisible, setIsVisible] = useState({
        text1: false,
        text2: false,
        text3: false,
        button: false,
        demo: false,
        text4: false,
        text5: false,
        text6: false,
    });

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const refButton = useRef(null);
    const refDemo = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);

    useEffect(() => {
        const options = {
            threshold: 0.1 // Trigger when 10% of the element is in view
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(prevState => ({
                        ...prevState,
                        [entry.target.dataset.id]: true
                    }));
                }
            });
        }, options);

        if (ref1.current) observer.observe(ref1.current);
        if (ref2.current) observer.observe(ref2.current);
        if (ref3.current) observer.observe(ref3.current);
        if (refButton.current) observer.observe(refButton.current);
        if (refDemo.current) observer.observe(refDemo.current);
        if (ref4.current) observer.observe(ref4.current);
        if (ref5.current) observer.observe(ref5.current);
        if (ref6.current) observer.observe(ref6.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className="main_section1">
            <section className="left_right_container">
                <div className="main_section1_content">
                    <h1 ref={ref1} data-id="text1" className={`main_section1_content_element ${isVisible.text1 ? 'show' : 'hidden'} cash-pooling`}>
                        Cash pooling
                    </h1>
                    <h2 ref={ref2} data-id="text2" className={`main_section1_content_element ${isVisible.text2 ? 'show' : 'hidden'} but-effortless`}>
                        But effortless
                    </h2>
                    <p ref={ref3} data-id="text3" className={`main_section1_content_element ${isVisible.text3 ? 'show' : 'hidden'} manage-your-etc`}>
                        Manage your cash pools with precise and automated arm’s length interest rates.
                    </p>
                    <img src={section1_laptop_pic_front} alt="" className="laptop-pic-front" />
                    <div className={`main_section1_content_element ${isVisible.button ? 'show' : 'hidden'} button_demo`}>
                        <ButtonScheduleCall ref={refButton} data-id="button"/>
                        <ButtonScheduleDemo ref={refDemo} data-id="demo"/>
                    </div>
                    <ul className="main--features">
                        <li ref={ref4} data-id="text4" className={`main--feature ${isVisible.text4 ? 'show' : 'hidden'}`}>Transfer pricing algorithm for OECD compliance</li>
                        <li ref={ref5} data-id="text5" className={`main--feature ${isVisible.text5 ? 'show' : 'hidden'}`}>Export reports, graphs, and assessments</li>
                        <li ref={ref6} data-id="text6" className={`main--feature ${isVisible.text6 ? 'show' : 'hidden'}`}>Foresee structural risks</li>
                    </ul>
                </div>
                
            </section>
            <section className="left_right_container" id="left_right_container-right">
                <div className="main_section1_content_2">
                    <img src={section1_laptop_pic} alt="" className="laptop-pic" />
                    <h3 className="main_section1_content_2_header">Audits are exhausting.<br/>How can you be sure your interest rates are compliant?</h3>
                    <p className="main_section1_content_2_p1">The reality is that humans make mistakes, even consultants. How fun was the last audit?</p>
                    <p className="main_section1_content_2_p2">There’s nothing worse than drawn-out information requests, delays, misunderstandings just to get a two-page pdf with mistakes.</p>
                    <p className="main_section1_content_2_p3">Our algorithm and cash pool management tool tailors your interest rate for each transaction, each company, each day.<br/>Without mistakes. Without overtime pay.</p>
                </div>
            </section>
        </div>
    )
}
