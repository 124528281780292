import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ScheduleCallModal(props) {

    const [submitted, setSubmitted] = useState(false)

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
          padding: theme.spacing(2),
          // backgroundColor: 'rgba(188, 203, 204, 1)',
        },
        '& .MuiDialogActions-root': {
          padding: theme.spacing(1),
          // backgroundColor: 'rgba(188, 203, 204, 1)',
        },
        '& .MuiDialog-paper': {
          backgroundColor: 'rgba(188, 203, 204, 1)',
          borderRadius: '25px',
        }
      }));


    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        // Send form data to Netlify via FormData API
        const formData = new FormData(form);

        fetch("/", {
            method: "POST",
            body: formData,
        })
        .then(() => setSubmitted(true))
        .catch((error) => console.error("Error submitting form:", error));
    };
        
    return (
        <BootstrapDialog
            maxWidth="sm"
            fullWidth="true"
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.isOpen}
            // open={true}
        >
            <h3 className="contact-form-header" id="customized-dialog-title">
                How can we reach you?
            </h3>
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    padding: '5px',
                    color: 'black',
                }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                <form className="contact-form" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                    {/* Hidden input for form-name */}
                    <input type="hidden" name="form-name" value="contact" />
                    
                    {/* Hidden honeypot field for spam protection */}
                    <p>
                        <label hidden>Don't fill this out: <input name="bot-field" /></label>
                    </p>

                    <p>
                        <label className='contact-form-label'>Name <input type="text" name="name" required/></label>
                    </p>
                    <p>
                        <label className='contact-form-label'>Email <input type="email" name="email" required/></label>
                    </p>
                    <p>
                        <label className='contact-form-label'>Phone<input type="tel" name="tel" required/></label>
                    </p>
                    <p>
                        <label className='contact-form-label'>Company <input type="text" name="company" required/></label>
                    </p>
                    <p>
                        <button className='contact-form-submit' type="submit" >Send</button>
                    </p>
                </form>
                { submitted && (
                   <div className="status_message" style={{whiteSpace: 'pre-line'}}>
                        Thank you. We will be in touch with you shortly.
                    </div> 
                )}
            </DialogContent>
        </BootstrapDialog>
    );
}